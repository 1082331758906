<template>
  <div
    class="overflow-y-auto absolute bottom-5 top-5 left-5 z-30 bg-white shadow w-module rounded-20"
  >
    <div class="header flex justify-between items-center pr-4 pl-6 pt-5 pb-2">
      <div class="text-xl font-bold whitespace-no-wrap font-SourceSansPro flex flex-col">
        События по объекту
        <span
          class="text-sm font-normal text-gray-500 truncate max-w-20"
          :title="currentEventsUnit.name"
        >
          {{ currentEventsUnit.name }}
        </span>
      </div>
      <CloseIcon @click.native="$emit('close')" class="cursor-pointer" />
    </div>
    <div class="px-4 mt-2">
      <el-button @click="createEvent" class="submit-btn">Создать событие</el-button>
    </div>
    <el-table
      class="overflow-y-auto"
      stripe
      style="width: 100%"
      :data="eventsListComputed"
      @row-click="showCurrentEvent"
    >
      <el-table-column prop="type.key">
        <template slot-scope="scope">
          <!-- Main type -->

          <span>
            {{ typesMap[scope.row.type.key] }}
          </span>
          <!-- Subtitle -->
          <span class="text-xsm font-normal text-gray-500">
            {{ scope.row.service_name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column align="right" prop="datetime" />
      <!-- small widht -->
      <el-table-column width="50">
        <template #default="scope">
          <el-popover
            v-model="scope.row.popoverVisible"
            placement="bottom"
            popper-class="setting-popover"
            :tabindex="Number(scope.row.id)"
            :visible-arrow="false"
            trigger="hover"
          >
            <template slot="reference">
              <div class="svg-container">
                <svg
                  width="4"
                  height="11"
                  viewBox="0 0 4 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.761719 1.36035C0.761719 1.71191 0.887695 2.01074 1.13965 2.25684C1.3916 2.50879 1.69336 2.63477 2.04492 2.63477C2.39648 2.63477 2.69824 2.50879 2.9502 2.25684C3.20215 2.00488 3.32812 1.70605 3.32812 1.36035C3.32812 1.00293 3.20215 0.698242 2.9502 0.446289C2.69824 0.200195 2.39648 0.0771484 2.04492 0.0771484C1.69336 0.0771484 1.3916 0.200195 1.13965 0.446289C0.887695 0.698242 0.761719 1.00293 0.761719 1.36035ZM0.761719 5.45605C0.761718 5.80762 0.887695 6.10937 1.13965 6.36133C1.3916 6.61328 1.69336 6.73926 2.04492 6.73926C2.39648 6.73926 2.69824 6.61328 2.9502 6.36133C3.20215 6.10937 3.32812 5.80762 3.32812 5.45605C3.32812 5.10449 3.20215 4.80273 2.9502 4.55078C2.69824 4.30469 2.39648 4.18164 2.04492 4.18164C1.69336 4.18164 1.3916 4.30469 1.13965 4.55078C0.887695 4.80273 0.761719 5.10449 0.761719 5.45605ZM0.761718 9.56055C0.761718 9.91211 0.887695 10.2109 1.13965 10.457C1.3916 10.709 1.69336 10.835 2.04492 10.835C2.39648 10.835 2.69824 10.709 2.95019 10.457C3.20215 10.2109 3.32812 9.91211 3.32812 9.56055C3.32812 9.20898 3.20215 8.90723 2.95019 8.65527C2.69824 8.40332 2.39648 8.27734 2.04492 8.27734C1.69336 8.27734 1.3916 8.40332 1.13965 8.65527C0.887695 8.90723 0.761718 9.20898 0.761718 9.56055Z"
                    fill="#3A7CCA"
                  />
                </svg>
              </div>
            </template>
            <div
              class="flex flex-col items-center justify-between gap-1 setting-popover__content"
            >
              <span
                @click.stop.prevent="showCurrentEvent(scope.row)"
                class="cursor-pointer"
              >
                Смотреть
              </span>
              <span
                v-if="scope.$index === 0"
                class="cursor-pointer text-blue-500"
                @click.stop.prevent="editEvent(scope.row, scope.$index)"
              >
                Редактировать</span
              >
              <span
                v-if="scope.$index === 0"
                class="cursor-pointer delete-btn"
                @click.stop.prevent="deleteEvent(scope.row)"
              >
                Удалить</span
              >
            </div>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <Event
      v-if="isShowCurrentEvent"
      class="current-event"
      :eventToUpdate="currentEvent"
      :title="currentTitle"
      :unit="currentEventsUnit"
      :isEventDisabled="isEventDisabled"
      @close="isShowCurrentEvent = false"
    />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from '@/store/store'
import { typesMap } from './utils/typesEvent'

import CloseIcon from './assets/icons/close-icon.vue'
import Event from './event.vue'
const Store = useStore()
const isEventDisabled = ref(false)
const isShowCurrentEvent = ref(false)
const currentEvent = ref({})
const currentTitle = ref(1)

const newEventList = array => {
  return array.map(el => ({
    ...el,
    popoverVisible: false
  }))
}

const showCurrentEvent = event => {
  currentEvent.value = event
  isEventDisabled.value = true
  isShowCurrentEvent.value = true
  currentTitle.value = 3

  event.popoverVisible = false
  eventsListComputed.value = newEventList(eventsList.value)
}

const currentEventsUnit = computed(() => {
  return Store.getters['events/getCurrentEventsUnit']
})
const eventsList = ref([])

const eventsListComputed = computed({
  get: () => {
    const list = Store.getters['events/getEvents']
    return list
      .filter(event => event.unit.id === currentEventsUnit.value.id)
      .map(event => ({
        ...event,
        popoverVisible: false
      }))
  },
  set: value => {
    eventsList.value = value
  }
})

const deleteEvent = event => {
  // the DELETE accept array of IDs
  Store.dispatch('events/DELETE', [event.id])
  event.popoverVisible = false
}

const editEvent = row => {
  row.popoverVisible = false
  eventsListComputed.value = newEventList(eventsList.value)

  currentEvent.value = row
  currentTitle.value = 2
  isEventDisabled.value = false
  isShowCurrentEvent.value = true
}
const createEvent = row => {
  currentEvent.value = null
  currentTitle.value = 1
  isEventDisabled.value = false
  isShowCurrentEvent.value = true
  row.popperVisible = false
}
</script>
<style lang="scss" scoped>
.current-event {
  position: fixed;
  top: 20px;
  left: 490px;
}
.submit-btn {
  width: 100%;
  margin-top: 10px;
  background: #2767b6;
  border-radius: 8px;
  border: none;
  color: #fff;
}
.delete-btn {
  color: #c93131;
}
.svg-container {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: center;
}
</style>
